<template>
  <Layout>
    <v-container fluid class="pa-0">
      <v-row class="accent">
        <v-col align="center" cols="12" md="4">
          <v-btn icon href="tel:+58 212 234 6020">
            <v-icon large class="mx-3 white--text">mdi-phone</v-icon> <h3 class="font-weight-bold white--text"> +58 212 234 6020</h3>
          </v-btn>
        </v-col>
        <v-col align="center" cols="12" md="4">
          <v-btn icon href="mailto:info@ajpyeventos.com">
            <v-icon large class="mx-3 white--text">mdi-gmail</v-icon> <h3 class="font-weight-bold white--text"> info@ajpyeventos.com</h3>
          </v-btn>
        </v-col>
        <v-col align="center" cols="12" md="4">
          <v-btn icon href="/contactanos">
            <v-icon large class="mx-3 white--text">mdi-map-marker-right</v-icon> <h3 class="font-weight-bold white--text"> Contáctanos</h3>
          </v-btn>
        </v-col>
      </v-row>
      <SlideEvents />
      <SlideClients />
    </v-container>
  </Layout>
</template>

<script>
export default {

  name: 'home',
  components: {
    SlideEvents: () => import('@/components/SlideEvents'),
    SlideClients: () => import('@/components/SlideClients'),
  },
  mounted(){
  }
}
</script>
